import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import type { ConfigContextType } from '../../../context/config.context';
import type { MarketContextType } from '../../../context/market.context';
import type { QueryStringContextType } from '../../../context/querystring.context';

import { Counter } from '../../Utility/Counter.ts';
import { ProductsUtility } from '../../Utility/ProductsUtility.ts';
import { SliceContainer } from '../SliceContainer/SliceContainer.tsx';
import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { ExperimentContext } from '../../../context/experiment.context.ts';
import { ExperimentContextType } from '../../../context/experiment.context.ts';

import { getAlteredSlices } from '../../Utility/xsUtility/getAlteredSlices.ts';
export interface SlicesContainerProps {
  slices: any;
  contentId?: string;
  category?: string;
  topLevelCategory?: string;
  region?: string;
  type: string;
}

export const SlicesContainer = ({
  slices: slicesInit,
  contentId = '',
  region,
  ...rest
}: SlicesContainerProps): JSX.Element | null => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const { pathname } = useLocation();
  const marketContext = useContext<MarketContextType>(MarketContext);
  const queryArgs = useContext<QueryStringContextType>(QueryStringContext);
  const { bucketedExperiments: experiments } =
    useContext<ExperimentContextType>(ExperimentContext);

  let slices = _.cloneDeep(slicesInit);

  const market = `${marketContext.country}-${marketContext.language}`;

  // If there are no slices, render nothing.
  if (!slicesInit || slicesInit.length <= 0) {
    return null;
  }

  // Alter slices based on XS experiments
  if (Object.keys(experiments).length) {
    for (const experiment in experiments) {
      const { attributes, configuration, experimentTextId } =
        experiments[experiment];
      // skip experiment if it's not in the region
      if (
        region &&
        configuration?.regions &&
        !configuration.regions.includes(region as 'header' | 'footer' | 'body')
      ) {
        continue;
      }

      slices = getAlteredSlices(
        slices,
        attributes,
        configuration,
        experimentTextId,
        pathname,
        market,
        region,
      );
    }
  }

  // On Checkout Page, filter the slices
  if (
    ProductsUtility.isCheckoutPage(
      queryArgs,
      pathname,
      translations,
      marketContext.country,
      marketContext.language,
    )
  ) {
    switch (region) {
      // In header, do nothing
      case 'header':
        break;

      // In footer, remove all slices except FooterLegalSlice
      case 'footer':
        slices = slices.filter(
          (slice: SlicesContainerProps['slices']) =>
            slice.__typename === 'FooterLegalSlice',
        );
        break;

      // In body, remove all slices except PlansDurationSlice
      case 'body':
        slices = slices.filter(
          (slice: SlicesContainerProps['slices']) =>
            slice.__typename === 'PlansDurationSlice',
        );
        break;

      default:
      // Do nothing;
    }
  }

  // If there is a PlansDurationSlice, add a PlansDisclaimerSlice to the end.
  if (_.find(slices, (slice) => slice.__typename === 'PlansDurationSlice')) {
    const id = `plans-disclaimer-slice-${Counter.getCounter(
      'plans-disclaimer-slice',
    )}`;
    slices.push({
      id,
      __typename: 'PlansDisclaimerSlice',
    });
  }

  // Applnanga-enabled StoryStream slice
  if (
    region === 'body' &&
    slices.length > 0 &&
    translations.STORYSTREAM_PATHS?.includes(pathname)
  ) {
    // Insert slice after slice with id located in list found in translations.STORYSTREAM_SLICE_IDS
    const index = slices.findIndex((slice: { id: string }) => {
      return translations.STORYSTREAM_SLICE_IDS?.includes(slice.id);
    });
    if (index >= 0) {
      const id = `storystream-slice-${Counter.getCounter('storystream-slice')}`;
      slices.splice(index + 1, 0, {
        id,
        __typename: 'StoryStreamSlice',
      });
    }
  }

  const sliceContainer = (slice: SlicesContainerProps['slices']) => {
    return (
      <SliceContainer
        key={slice.id}
        slice={slice}
        contentId={contentId}
        region={region}
        xsExperimentTextId={slice.xsExperimentTextId}
        {...rest}
      />
    );
  };

  const sliceContainerWithId = (slice: SlicesContainerProps['slices']) => {
    return slice.__typename === 'NavigationSlice' && slice.sticky ? (
      sliceContainer(slice)
    ) : (
      <div id={`slice-id--${slice.id}`}>{sliceContainer(slice)}</div>
    );
  };

  return (
    <>
      {slices.map((slice: SlicesContainerProps['slices']) => (
        <React.Fragment key={slice.id}>
          {sliceContainerWithId(slice)}
        </React.Fragment>
      ))}
    </>
  );
};
